'use client'

import Link from '@dg/common/components/Link'
import {
	device
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import pathsData from '@dg/common/public/json/mobile/paths'
import pathsPcData from '@dg/common/public/json/pc/paths'
import Image from 'next/image'
import {
	useEffect
} from 'react'
import {
	useTranslation
} from 'react-i18next'

const Custom404Page = () => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	useEffect(() => {
		const errorMutation = {
			callback: (mutationsList: MutationRecord[]) => {
				for (const mutation of mutationsList) {
					const obj = mutation.target as HTMLElement

					const header = obj.querySelector(`#wrap-header`) as HTMLElement
					const content = obj.querySelector(`#wrap-content`) as HTMLElement

					content.style.paddingBottom = ``
					content.style.minHeight = `${window.innerHeight - header.offsetHeight}px`
				}
			},
			config: {
				attributes: true
			},
			obj: document.querySelector(`body`) as HTMLElement ?? null
		}

		const errorMutationObserver = new MutationObserver(errorMutation.callback)

		if (errorMutation.obj !== null) {
			errorMutationObserver.disconnect()

			errorMutationObserver.observe(errorMutation.obj, errorMutation.config)
		}

		const cleanup = () => {
			if (errorMutation.obj !== null) {
				errorMutationObserver.disconnect()
			}
		}

		return cleanup
	}, [])

	return (
		<>
			<style>
				{
					`
						#wrap-content {
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: #fcfcfd;
						}
					`
				}
			</style>

			<section
				id="error"
			>
				<div
					className="flex size-full flex-col items-center justify-center"
				>
					<Image
						alt="Page Not Found"
						className="object-cover"
						height={51}
						onError={imageValidate.onError}
						src="/images/common/404.png"
						width={144}
					/>

					<strong
						className="mt-8 block text-lg transition-colors"
					>
						{t(`다시 확인해 주세요.`)}
					</strong>

					<span
						className="mb-6 mt-4 block text-sm text-dg-600 dark:text-dgdark-600"
						dangerouslySetInnerHTML={
							{
								__html: t(`URL이나 파일 이름이 잘못되었거나, 이미 삭제, 이동된 페이지입니다.`)
							}
						}
					/>

					<Link
						className="border border-solid border-dg-500 dark:border-dgdark-500"
						href={(device().browser.mobile === true ? pathsData : pathsPcData).home}
						pd
					>
						{t(`홈`)}
					</Link>

					<span
						className="mt-25 block text-sm text-dg-600 dark:text-dgdark-600"
					>
						404 Not Found
					</span>
				</div>
			</section>
		</>
	)
}

export default Custom404Page
